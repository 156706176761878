import { Separator } from '@/components/ui/separator'
import {
    TypographyLarge,
    TypographyP,
    TypographyXS,
} from '@/components/ui/typography'
import { useCostHistoryQuery } from '@/queries/useHistoryQuery'
import { useParams } from 'react-router-dom'
import HistorySkeleton from './components/HistorySkeleton'
import HistoryItem from './components/HistoryItem'
import { format, isToday, isYesterday, parseISO } from 'date-fns'
import HistoryDetailDialog from './components/HistoryDetailDialog'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useState, useEffect, useRef } from 'react'
import { MudancaCusto } from '@/api/business/structureStatus/type'

type SortedHistory =
    | { type: 'date'; date: string }
    | ({
          type: 'cost'
      } & MudancaCusto)

const HistorySection = () => {
    const { id } = useParams()
    const { data } = useCostHistoryQuery({ structureId: id! })
    const [sortedHistory, setSortedHistory] = useState<SortedHistory[]>([])
    const parentRef = useRef(null)

    useEffect(() => {
        if (data) {
            const sorted = Object.entries(data.MUDANCAS_POR_DATA)
                .sort(([dateA], [dateB]) => {
                    return parseISO(dateB).getTime() - parseISO(dateA).getTime()
                })
                .flatMap(([date, history]) => {
                    const dateObject = parseISO(date)
                    let dateLocale = format(dateObject, 'dd/MM/yyyy')

                    if (isYesterday(dateObject)) {
                        dateLocale = 'Ontem'
                    } else if (isToday(dateObject)) {
                        dateLocale = 'Hoje'
                    }

                    return [
                        { type: 'date' as const, date: dateLocale },
                        ...history.map((cost) => ({
                            type: 'cost' as const,
                            ...cost,
                        })),
                    ]
                })

            setSortedHistory(sorted)
        }
    }, [data])

    const rowVirtualizer = useVirtualizer({
        count: sortedHistory.length,
        getScrollElement: () => parentRef.current,
        estimateSize: () => 40,
        overscan: 5,
    })

    const items = rowVirtualizer.getVirtualItems()

    if (!data) return <HistorySkeleton />

    return (
        <div className="w-[340px] xl:w-[420px] flex flex-col p-6 bg-background rounded-lg shadow-lg overflow-hidden">
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <TypographyLarge>Histórico custo</TypographyLarge>
                    <HistoryDetailDialog />
                </div>
                <Separator />
            </div>
            {sortedHistory.length === 0 && (
                <div className="py-2">
                    <TypographyP className="text-muted-foreground">
                        Não há histórico para esse produto
                    </TypographyP>
                </div>
            )}
            <div
                ref={parentRef}
                className="flex-1"
                style={{
                    height: '400px',
                    overflowY: 'auto',
                    contain: 'strict',
                }}
            >
                <div
                    style={{
                        height: rowVirtualizer.getTotalSize(),
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            transform: `translateY(${items[0]?.start ?? 0}px)`,
                        }}
                    >
                        {items.map((virtualRow) => {
                            const item = sortedHistory[virtualRow.index]
                            return (
                                <div
                                    key={virtualRow.key}
                                    data-index={virtualRow.index}
                                    ref={rowVirtualizer.measureElement}
                                >
                                    {item.type === 'date' ? (
                                        <div className="sticky top-2">
                                            <div className="flex items-center justify-center my-2">
                                                <div className="px-2 py-1 rounded-full shadow shrink-0 bg-primary-50 dark:bg-primary-400/30">
                                                    <TypographyXS className="text-primary-700 dark:text-primary">
                                                        {item.date}
                                                    </TypographyXS>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <HistoryItem item={item} />
                                    )}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HistorySection
